import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

const images = [
  'https://source.unsplash.com/2ShvY8Lf6l0/800x599',
  'https://source.unsplash.com/Dm-qxdynoEc/800x799',
  'https://source.unsplash.com/qDkso9nvCg0/600x799',
  'https://source.unsplash.com/iecJiKe_RNg/600x799',
  'https://source.unsplash.com/epcsn8Ed8kY/600x799',
  'https://source.unsplash.com/NQSWvyVRIJk/800x599',
  'https://source.unsplash.com/zh7GEuORbUw/600x799',
  'https://source.unsplash.com/PpOHJezOalU/800x599',
  'https://source.unsplash.com/I1ASdgphUH4/800x599'
];

export default class LightboxExample extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    const { photoIndex, isOpen } = this.state;

    return (
      <div>
        <button type="button" onClick={() => this.setState({ isOpen: true })}>
          Open Lightbox
        </button>

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}
